<template>
    <div class="">
        <div class="max-w-lg mx-auto flex flex-col items-center justify-center min-h-screen">
            <img
                :src="require('@/assets/images/error_page.svg')"
                alt="error page illustration"
            >
            <h1 class="font-4xl mt-30 mb-10 text-gray-800">{{ $t('pageNotFoundTitle') }}</h1>
            <p class="p text-gray-800">{{ $t('pageNotFoundSubtitle') }}</p>
            <a
                class="button bg-indigo-400 text-white hover:bg-indigo-300 flex items-center justify-center mt-30"
                href="/"
            >{{ $t('pageNotFoundLinkText') }}</a>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
</style>
