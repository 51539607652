import axios from 'axios'

const state = {
    services: [],
    inProgressRequests: [],
    receivedServices: [],
    addRequestStatus: false,
    editRequestStatus: false,
    deleteRequestStatus: false,
    shareRequestStatus: false,
    discardRequestStatus: false,
    serviceSharedAmount: ''
}

const getters = {
    allServices: state => state.services,
    inProgressRequests: state => state.inProgressRequests,
    receivedServices: state => state.receivedServices,
    addRequestStatus: state => state.addRequestStatus,
    editRequestStatus: state => state.editRequestStatus,
    deleteRequestStatus: state => state.deleteRequestStatus,
    shareRequestStatus: state => state.shareRequestStatus,
    discardRequestStatus: state => state.discardRequestStatus,
    serviceSharedAmount: state => state.serviceSharedAmount
}

const actions = {
    /** Action to get all the services */
    async getServices ({ commit }, payload) {
        await axios.get('service-list', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setServices', response.data)
            })
            .catch(error => {
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Action to get all the requsted services list. */
    async getSentRequests ({ commit }, payload) {
        await axios.get('request-sent-ngo', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setInProgressRequests', response.data)
            })
            .catch(error => {
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Action to get all the received services from other ngos. */
    async getReceivedRequests ({ commit }, payload) {
        await axios.get('request-received-ngo', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setReceivedServices', response.data)
            })
            .catch(error => {
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Adding a new service. */
    async addService ({ commit }, payload) {
        const request = await axios.post('service-create', payload, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                commit('addService', response.data)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('addRequestStatus', true)

                return response
            })
            .catch(error => {
                commit('addRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }

                return error
            })

        return request
    },

    /** Editing a service. */
    async editServiceRequest ({ commit }, payload) {
        // eslint-disable-next-line camelcase
        const { shared_amount, allocated_amount, ...serviceToEdit } = payload.service
        const service = {
            service: serviceToEdit
        }
        await axios.post('service-update', service, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                commit('changeService', payload.service)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('editRequestStatus', true)
            })
            .catch(error => {
                commit('editRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async deleteServiceRequest ({ commit }, payload) {
        await axios.post('service-delete', payload.service, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                commit('deleteService', payload.service)

                commit('deleteRequestStatus', true)
            })
            .catch(error => {
                commit('deleteRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Share service. */
    async shareService ({ commit }, payload) {
        await axios.post('service-share', payload, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('shareRequestStatus', true)
                commit('updateHoursShared', payload)
            })
            .catch(error => {
                commit('shareRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async discardRequestService ({ commit }, payload) {
        // eslint-disable-next-line camelcase
        const { list, ...requestToUpdate } = payload.request
        const request = {
            request: requestToUpdate
        }
        await axios.post('request-update', request, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('discardRequest', payload.request)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('discardRequestStatus', true)
            })
            .catch(error => {
                commit('discardRequestStatus', true)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    }
}

const mutations = {
    /** Update shared hours for service */
    updateHoursShared: (state, serviceToUpdate) => {
        for (const service in state.services) {
            if (state.services[service].id === serviceToUpdate.service.id) {
                state.services[service].shared_amount = serviceToUpdate.service.shared_amount
            }
        }
    },
    /** Set all services mutation. */
    setServices: (state, services) => {
        state.services = services
    },
    /** Set all services mutation. */
    setInProgressRequests: (state, requests) => {
        state.inProgressRequests = requests
    },
    /** Set all services mutation. */
    setReceivedServices: (state, services) => {
        state.receivedServices = services
    },
    /** Add a new service mutation. */
    addService: (state, service) => {
        state.services.unshift(service)
    },
    /** Edit a service mutation. */
    changeService: (state, serviceEdit) => {
        for (const service in state.services) {
            if (state.services[service].id === serviceEdit.id) {
                state.services[service] = serviceEdit
            }
        }
        state.services = [...state.services]
    },
    deleteService: (state, serviceToDelete) => {
        for (const service in state.services) {
            if (state.services[service].id === serviceToDelete.service_id) {
                state.services.splice(service, 1)
            }
        }
    },
    /** Discard a service request mutation. */
    discardRequest: (state, requestToDiscard) => {
        if (requestToDiscard.list === 'inProgress') {
            for (const request in state.inProgressRequests) {
                if (state.inProgressRequests[request].id === requestToDiscard.id) {
                    state.inProgressRequests.splice(request, 1)
                }
            }

            state.inProgressRequests = [...state.inProgressRequests]
        } else {
            for (const request in state.receivedServices) {
                if (state.receivedServices[request].id === requestToDiscard.id) {
                    state.receivedServices.splice(request, 1)
                }
            }

            state.receivedServices = [...state.receivedServices]
        }
    },
    /** Mutation for the status of the add request. */
    addRequestStatus: (state, status) => {
        state.addRequestStatus = status
    },
    /** Mutation for the status of the edit request. */
    editRequestStatus: (state, status) => {
        state.editRequestStatus = status
    },
    deleteRequestStatus: (state, status) => {
        state.deleteRequestStatus = status
    },
    /** Mutation for the status of the edit request. */
    shareRequestStatus: (state, status) => {
        state.shareRequestStatus = status
    },
    /** Mutation for the status of the edit request. */
    discardRequestStatus: (state, status) => {
        state.discardRequestStatus = status
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
