import axios from 'axios'
import router from '@/router'
import i18n from '../../../i18n'

const state = {
    /** Data object used for resetPassword-init fields. */
    init_Data: {
        email: ''
    },
    activation_code: '',
    sms_code: ''
}

const getters = {
    /** Getter for initial reset password state. */
    resetData: (state) => state.init_Data,
    activationCodeResetPassword: (state) => state.activation_code,
    smsCodeResetPassword: (state) => state.sms_code
}

const actions = {
    /** Post request action for resetpassword-init form. */
    async resetPasswordInit ({ commit }, payload) {
        await axios.post('password-reset-init', payload)
            .then(response => {
                localStorage.setItem('email', payload.email)

                /** Clean state of errors */
                commit('errors', '')

                /** If success redirect to success message page. */
                router.push({ name: 'Success', params: { successTitle: i18n.t('succesMessagePassResetTitle'), successSubtitle: i18n.t('successMessagePassResetSubtitle') } })
            })
            .catch(errors => {
                console.log(errors.response)
                /** If post request fails commit erros to mutation. */
                commit('errors', errors.response.data.errors)
            })
    },

    /** Post request action for reset password activate code form. */
    async resetPasswordActivateCode ({ commit }, code) {
        await axios.post('password-reset-confirm', code)
            .then(response => {
                /** Commit mutation for signup activate code. */
                commit('resetPasswordActivateCodeCommit', code.reset_password_code)

                /** Clean state of errors */
                commit('error', '')

                /** If success redirect to enter code page. */
                router.push({ name: 'ResetPasswordValidate' })
            })
            .catch(error => {
                /** If post request fails commit error to mutation. */
                commit('error', error.response.data.error)
            })
    },

    /** Post request action for signup validate code form. */
    async resetPasswordValidateCode ({ commit }, payload) {
        await axios.post('password-reset-validate', payload)
            .then(response => {
                /** Commit mutation for signup validate code. */
                commit('resetPasswordValidateCodeCommit', payload.sms_code)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** If success redirect to set password page. */
                router.push({ name: 'ResetPasswordChange' })
            })
            .catch(validateCodeErrors => {
                if (typeof validateCodeErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit errors to mutation. */
                    commit('errors', validateCodeErrors.response.data.errors)
                }

                if (typeof validateCodeErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit error to mutation. */
                    commit('error', validateCodeErrors.response.data.error)
                }
            })
    },

    /** Post request action for reset password set password form. */
    async resetPasswordSetPassword ({ commit }, payload) {
        await axios.post('password-reset-change', payload)
            .then(response => {
                /** Remove "activation_code" and "sms_code" from state. */
                commit('resetPasswordActivateCodeCommit', null)
                commit('resetPasswordValidateCodeCommit', null)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** If success redirect to set login page. */
                router.push({ name: 'Login' })
            })
            .catch(setPasswordErrors => {
                if (typeof setPasswordErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', setPasswordErrors.response.data.errors)
                }

                if (typeof setPasswordErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', setPasswordErrors.response.data.error)
                }
            })
    }
}

const mutations = {
    /** Reset password activate code mutation. */
    resetPasswordActivateCodeCommit: (state, code) => (state.activation_code = code),
    /** Reset password validate code */
    resetPasswordValidateCodeCommit: (state, code) => (state.sms_code = code)
}

export default {
    state,
    getters,
    actions,
    mutations
}
