import axios from 'axios'
import router from '@/router'

const state = {
    email: '',
    password: ''
}

const getters = {
    email: (state) => state.email,
    password: (state) => state.password
}

const actions = {

    /** Post request action for signup-init form. */
    async loginInit ({ commit }, payload) {
        await axios.post('login-init', payload)
            .then(response => {
                /** Save the "email" in the state. */
                commit('loginEmailCommit', payload.email)
                /** Save the "password" in the state. */
                commit('loginPasswordCommit', payload.password)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** If success redirect to enter code page. */
                router.push({ name: 'LoginValidate' })
            })
            .catch(loginInitErrors => {
                if (typeof loginInitErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', loginInitErrors.response.data.errors)
                }

                if (typeof loginInitErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', loginInitErrors.response.data.error)
                }
            })
    },

    /** Post request action for signup-init form. */
    async loginValidate ({ commit }, payload) {
        await axios.post('login-validate', payload)
            .then(response => {
                /** Commit mutation for signup auth token. */
                commit('signupSetTokenCommit', response.data.token)
                /** Commit mutation for signup user type. */
                commit('signupSetTypeCommit', response.data.type)

                /** Save token and user type in localStorage. */
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('type', response.data.type)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** Remove "email" and "password" from state. */
                commit('loginEmailCommit', null)
                commit('loginPasswordCommit', null)

                /** If success redirect to set password page. */
                router.push({ name: 'Dashboard' })
            })
            .catch(loginValidateErrors => {
                /** If errors remove token from localStorage */
                localStorage.removeItem('token')
                localStorage.removeItem('type')

                if (typeof loginValidateErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', loginValidateErrors.response.data.errors)
                }

                if (typeof loginValidateErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', loginValidateErrors.response.data.error)
                }
            })
    }
}

const mutations = {
    /** Login email mutation. */
    loginEmailCommit: (state, email) => (state.email = email),
    /** Login password mutation. */
    loginPasswordCommit: (state, password) => (state.password = password),
    loginValidateCommit: (state, payload) => (state = payload)
}

export default {
    state,
    getters,
    actions,
    mutations
}
