import axios from 'axios'

const state = {}

const getters = {}

const actions = {

    /** Ger request for refresh user type. */
    async getRefreshUserType ({ commit }, payload) {
        const getRefreashUserType = axios.get('profile-type', { headers: { Authorization: `Bearer ${payload.token}` } })

        getRefreashUserType
            .then(response => {
                /** If success extract data to fetchProfileCommit. */
                localStorage.setItem('type', response.data.type)
                commit('signupSetTypeCommit', response.data.type)
            })
            .catch(error => {
                /** If error log the error. */
                console.log(error.response)
            })

        return getRefreashUserType
    }
}

const mutations = {}

export default {
    state,
    getters,
    actions,
    mutations
}
