import axios from 'axios'

const state = {
    files: [],
    progressBar: 0,
    uploadedFiles: [],
    formFile: {},
    fileUploaded: false
}

const getters = {
    fileList: state => state.files,
    progress: state => state.progressBar,
    uploadedFiles: state => state.uploadedFiles,
    formFile: state => state.formFile,
    fileUploaded: state => state.fileUploaded
}

const actions = {
    /** Upload files function. */
    async uploadFiles ({ commit }, payload) {
        const formData = new FormData()

        formData.append('file', payload.file)
        formData.append('token', payload.token)
        formData.append('case_id', payload.case_id)

        const files = axios.post('file-upload', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${payload.token}`
            },
            /** Progress bar */
            onUploadProgress: progressEvent => {
                const progressBar = document.getElementById(payload.progressBarId)
                progressBar.style.width = (progressEvent.loaded / progressEvent.total * 100) + '%'
            }
        })

        await files
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('setFileId', response.data)
                commit('updateRequestStatus', true)
                commit('fileWasUploaded', true)
            })
            .catch(signupInitErrors => {
                commit('updateRequestStatus', false)
                commit('fileWasUploaded', false)

                if (typeof signupInitErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', signupInitErrors.response.data.error)
                }
            })
    },

    /** Delete file. */
    async deleteFile ({ commit }, payload) {
        await axios.post(
            'file-delete',
            /* body */{
                token: payload.token,
                case_id: payload.case_id,
                file_id: payload.file_id
            },
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            .then(response => {
                /**
                 * Remove from state the file that was deleted.
                 * Either from the currenlty uploaded filed, or
                 * from the case directly.
                */
                if (state.uploadedFiles.length > 0) {
                    commit('removeUploadedFile', payload.array_index)
                } else {
                    commit('removeCaseFile', payload)
                }

                /** Clean state of errors */
                commit('error', '')

                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Download all files */
    async downloadAllFiles ({ commit }, payload) {
        const archiveName = payload.archive_name
        const downloadFiles = axios.get(`file-download-archive?case_id=${payload.case_id}`, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${payload.token}`
            }

        })
        downloadFiles
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', 'Case#' + archiveName + '-files' + '.zip')
                document.body.appendChild(link)
                link.click()
                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })

        return downloadFiles
    },

    /** Download one file */
    async downloadOneFile ({ commit }, payload) {
        const fileName = payload.file_name
        const downloadFiles = axios.get(`file-download?case_id=${payload.case_id}&file_id=${payload.file_id}`, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${payload.token}`
            }

        })
        downloadFiles
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', fileName)
                document.body.appendChild(link)

                link.click()
                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })

        return downloadFiles
    },

    /** Action to get the form for editing. */
    async getFormFile ({ commit }, payload) {
        const getData = axios.get(`form-file-get?case_id=${payload.case_id}&file_id=${payload.file_id}`, { headers: { Authorization: `Bearer ${payload.token}` } })

        getData
            .then(response => {
                commit('setFormFile', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })

        return getData
    },

    /** Action for saving the form as pdf. */
    async saveFormFile ({ commit }, payload) {
        const formData = new FormData()

        formData.append('form_values', payload.form_values)
        formData.append('form_pdf', payload.form_pdf)
        formData.append('token', payload.token)
        formData.append('case_id', payload.case_id)
        formData.append('form_id', payload.form_id)

        await axios.post('form-file-create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** Status for form create. */
                commit('updateRequestStatus', true)
            })
            .catch(error => {
                /** Status for form create. */
                commit('updateRequestStatus', false)
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Action for updating the form as pdf. */
    async updateFormFile ({ commit }, payload) {
        const formData = new FormData()

        formData.append('form_values', payload.form_values)
        formData.append('form_pdf', payload.form_pdf)
        formData.append('token', payload.token)
        formData.append('case_id', payload.case_id)
        formData.append('file_id', payload.file_id)

        await axios.post('form-file-update', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** Status for form create. */
                commit('updateRequestStatus', true)
            })
            .catch(error => {
                /** Status for form create. */
                commit('updateRequestStatus', false)
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Clear uploaded files */
    async clearUploadedFiles ({ commit }) {
        commit('clearUploadedFile')
    }

}

const mutations = {
    fileWasUploaded: (state, status) => (state.fileUploaded = status),
    setFileId: (state, uploadedFiles) => (state.uploadedFiles.push(uploadedFiles)),
    setFormFile: (state, file) => (state.formFile = file),
    removeUploadedFile: (state, index) => state.uploadedFiles.splice(index, 1),
    clearUploadedFile: (state) => state.uploadedFiles.splice(0, state.uploadedFiles.length)
}

export default {
    state,
    getters,
    actions,
    mutations
}
