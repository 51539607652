<template>
    <li
        class="link-container"
        @click="$emit('clicked')"
    >
        <router-link
            class="link nav-link text-purple-600"
            :to="url"
        >
            <div>
                <img
                    :src="img"
                    :alt="alt"
                >
            </div>
            {{linkText}}
        </router-link>
    </li>
</template>

<script>
export default {
    name: 'Link',
    props: ['url', 'linkText', 'img', 'alt']

}
</script>
