import axios from 'axios'

const state = {}

const getters = {}

const actions = {
    /** Post request for token refresh. */
    async refreshToken ({ commit }, payload) {
        const response = await axios.post('refresh', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
        localStorage.setItem('token', response.data.token)
        commit('signupSetTokenCommit', response.data.token)

        await this.dispatch('getRefreshUserType', { token: response.data.token }, { root: true })

        return response.data.token
    }
}

const mutations = {}

export default {
    state,
    getters,
    actions,
    mutations
}
