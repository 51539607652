import Vue from 'vue'
import Vuex from 'vuex'
import members from './modules/members/members'
import signup from './modules/authentification/signup'
import login from './modules/authentification/login'
import cases from './modules/cases/cases'
import services from './modules/services/services'
import networkServices from './modules/services/networkServices'
import requestedServices from './modules/services/requestedServices'
import staticData from './modules/static-data/staticData'
import resetPassword from './modules/authentification/resetPassword'
import logout from './modules/authentification/logout'
import refreshToken from './modules/authentification/refreshToken'
import general from './modules/general'
import profile from './modules/profile/profile'
import forms from './modules/form-builder/formBuilder'
import files from './modules/files/files'
import refreashUserType from './modules/authentification/refreashUserType'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        general,
        members,
        signup,
        login,
        logout,
        refreshToken,
        cases,
        services,
        networkServices,
        requestedServices,
        staticData,
        resetPassword,
        profile,
        forms,
        files,
        refreashUserType
    }
})
