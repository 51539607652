import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SmartTable from 'vuejs-smart-table'
import moment from 'vue-moment'
import './assets/styles/css/app.css'
import i18n from './i18n'
import axios from 'axios'

Vue.config.productionTip = false
Vue.use(SmartTable)
Vue.use(moment)

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

axios.interceptors.response.use((response) => {
    /** Return a successful response back to the calling service */
    return response
}, (error) => {
    /** Return any error which is not due to authentication back to the calling service */
    if (error.response.status !== 401) {
        if (error.config.url !== 'refresh') {
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }
    }

    /** Logout user if token refresh didn't work or user is disabled */
    if (error.config.url === 'refresh') {
        /** Commit mutation for signup user type and token */
        store.commit('signupSetTypeCommit', null)
        store.commit('signupSetTokenCommit', null)

        /** Remove token and user type from localStorage. */
        localStorage.removeItem('token')
        localStorage.removeItem('type')

        /** Go to login if refreshing was not ok. */
        router.push({ name: 'Login' })

        return new Promise((resolve, reject) => {
            reject(error)
        })
    }

    /** Try request again with new token */
    return store.dispatch('refreshToken', { token: store.getters.token })
        .then((token) => {
            /** New request with new token */
            const config = error.config
            config.headers.Authorization = `Bearer ${token}`

            return new Promise((resolve, reject) => {
                axios.request(config).then(response => {
                    resolve(response)
                }).catch((error) => {
                    reject(error)
                })
            })
        })
        .catch((error) => {
            Promise.reject(error)
        })
})

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
