import axios from 'axios'

const state = {
    requestedServices: [],
    requestStatistics: {},
    updateRequestStatus: false,
    lastRequestedServices: []
}

const getters = {
    allRequestedServices: state => state.requestedServices,
    requestStatistics: state => state.requestStatistics,
    updateRequestStatus: state => state.updateRequestStatus,
    lastRequestedServices: (state) => state.lastRequestedServices
}

const actions = {
    async getRequestedServices ({ commit }, payload) {
        await axios.get('request-list', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setRequestedServices', response.data)
            })
            .catch(error => {
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async getRequestStatistics ({ commit }, payload) {
        await axios.get('request-statistics', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setRequestStatistics', response.data)
            })
            .catch(error => {
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async updateRequest ({ commit }, payload) {
        const { amount, message, ngo, service, ...requestToUpdate } = payload.request
        const request = {
            request: requestToUpdate
        }
        await axios.post('request-update', request, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('updateRequest', payload.request)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async getRequestedLastServices ({ commit }, payload) {
        await axios.get('request-last-list', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setRequestedLastServices', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })
    }
}

const mutations = {
    setRequestedServices: (state, services) => {
        state.requestedServices = services
    },
    setRequestedLastServices: (state, services) => {
        state.lastRequestedServices = services
    },
    setRequestStatistics: (state, statistics) => {
        state.requestStatistics = statistics
    },
    updateRequest: (state, requestToEdit) => {
        for (const request in state.requestedServices) {
            if (state.requestedServices[request].id === requestToEdit.id) {
                state.requestedServices[request] = requestToEdit
            }
        }
        state.requestedServices = [...state.requestedServices]
    },
    /** Mutation for the status of the update request. */
    updateRequestStatus: (state, status) => {
        state.updateRequestStatus = status
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
