import axios from 'axios'
import router from '@/router'

const state = {}

const getters = {}

const actions = {

    /** Post request action for signup-init form. */
    async logout ({ commit }, payload) {
        await axios.post(
            'logout',
            payload,
            {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            .then(response => {
                /** Commit mutation for signup auth token. */
                commit('signupSetTokenCommit', null)
                /** Commit mutation for signup user type. */
                commit('signupSetTypeCommit', null)

                /** Remove token and user type from localStorage. */
                localStorage.removeItem('token')
                localStorage.removeItem('type')

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** If success redirect to set password page. */
                router.push({ name: 'Login' })
            })
            .catch(logoutErrors => {
                if (typeof logoutErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', logoutErrors.response.data.errors)
                }

                if (typeof logoutErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', logoutErrors.response.data.error)
                }
            })
    }
}

const mutations = {}

export default {
    state,
    getters,
    actions,
    mutations
}
