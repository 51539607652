<template>
    <div class="grid grid-cols-12 w-full relative">
        <!-- Sidebar -->
        <SideBarNav />
        <div class="dashboard-container bg-trueGray-200">
            <router-view />
            <div class="bottom-0 w-full">
                <ul class="flex flex-wrap mx-30 my-60">
                    <li class="py-10 small text-gray-500">
                        <a class="underline" :href="`${homeLink}`" target="_blank">{{$t('homeText')}}</a><span class="px-12 py-15 small">&#124;</span>
                    </li>
                    <li class="py-10 small text-gray-500">
                        <a class="underline" :href="`${termsLink}`" target="_blank">{{$t('termsAndConditionText')}}</a><span class="px-12 py-15 small">&#124;</span>
                    </li>
                    <li class="py-10 small text-gray-500">
                        <a class="underline" :href="`${privacyLink}`" target="_blank">{{ $t('privacyPolicyText') }}</a><span class="px-12 py-15 small">&#124;</span>
                    </li>
                    <li class="py-10 small text-gray-500">
                        <a class="underline" :href="`${contactLink}`" target="_blank">{{$t('contactText')}}</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import SideBarNav from '@/components/navigation/SideBarNav'
export default {
    name: 'Home',
    components: {
        SideBarNav
    },
    data () {
        return {
            homeLink: process.env.VUE_APP_WEBSITE,
            termsLink: process.env.VUE_APP_TERMS_AND_CONDITIONS,
            privacyLink: process.env.VUE_APP_PRIVACY_POLICY,
            contactLink: process.env.VUE_APP_CONTACT
        }
    }
}
</script>
