import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/dashboard/Home.vue'
import NotFound from '../views/NotFound.vue'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
    // ** General route. */
    {
        path: '/',
        redirect: '/dashboard',
        name: 'Home',
        component: Home,
        props: true,
        children: [
            /** Dashboard. */
            {
                path: '/dashboard',
                name: 'Dashboard',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard" */ '../views/dashboard/Dashboard.vue'
                    )
            },
            /** View all cases. */
            {
                path: '/cases',
                name: 'Cases',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "cases" */ '../views/dashboard/cases/Cases.vue')
            },
            /** Case details. */
            {
                path: '/cases/:slug',
                name: 'CaseDetails',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "cases-details" */ '../views/dashboard/cases/CaseDetails.vue')
            },
            /** Add case. */
            {
                path: '/case-add',
                name: 'CaseAdd',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "cases-add" */ '../views/dashboard/cases/CaseAdd.vue'
                    )
            },
            /** Edit case. */
            {
                path: '/cases/case-edit/:slug',
                name: 'CaseEdit',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "case-edit" */ '../views/dashboard/cases/CaseEdit.vue'
                    )
            },
            /** All services. */
            {
                path: '/services/all-services',
                name: 'Services',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "all-services" */ '../views/dashboard/services/AllServices.vue')
            },
            /** Network Services. */
            {
                path: '/services/network-services',
                name: 'NetworkServices',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "network-services" */ '../views/dashboard/services/NetworkServices.vue')
            },
            /** Request services. */
            {
                path: '/services/requested-services',
                name: 'ServiceRequests',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "request-services" */ '../views/dashboard/services/ServiceRequests.vue')
            },
            /** Members. */
            {
                path: '/members',
                name: 'Members',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "members" */ '../views/dashboard/Members.vue')
            },
            /** Forms. */
            {
                path: '/forms',
                name: 'Forms',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "forms" */ '../views/dashboard/Forms.vue')
            },
            {
                path: '/form-create/:slug?',
                name: 'FormCreate',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "form-create" */ '../views/dashboard/FormCreate.vue')
            },
            {
                path: '/form-preview/:slug?',
                name: 'FormPreview',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "form-create" */ '../views/dashboard/FormPreview.vue')
            },
            /** Profile. */
            {
                path: '/profile',
                name: 'Profile',
                props: true,
                meta: { requiresAuth: true },
                component: () =>
                    import(
                        /* webpackChunkName: "profile" */ '../views/dashboard/Profile.vue')
            }
        ]
    },
    /** SignupInit. */
    {
        path: '/signup',
        name: 'SignupInit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "signup-init" */ '../views/authentication/signup/SignupInit.vue'
            )
    },
    /** Sign up activate code. */
    {
        path: '/signup-activate',
        name: 'SignupActivate',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "signup-activate" */ '../views/authentication/signup/SignupActivate.vue'
            )
    },
    /** Signup register code route. */
    {
        path: '/signup-validate',
        name: 'SignupValidate',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "signup-validate" */ '../views/authentication/signup/SignupValidate.vue'
            )
    },
    /** Signup set password. */
    {
        path: '/signup-password',
        name: 'SignupPassword',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "signup-password" */ '../views/authentication/signup/SignupPassword.vue'
            )
    },
    /** Login route. */
    {
        path: '/login',
        name: 'Login',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "login" */ '../views/authentication/login/Login.vue'
            )
    },
    /** Authentification code route. */
    {
        path: '/login-validate',
        name: 'LoginValidate',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "login-validate" */ '../views/authentication/login/LoginValidate.vue'
            )
    },
    /** Reset password route. */
    {
        path: '/reset-password',
        name: 'ResetPasswordInit',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "auth-code" */ '../views/authentication/reset-password/ResetPasswordInit.vue'
            )
    },
    /** Reset password route. */
    {
        path: '/reset-password-confirm',
        name: 'ResetPasswordConfirm',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "auth-code" */ '../views/authentication/reset-password/ResetPasswordConfirm.vue'
            )
    },
    /** Reset password code route. */
    {
        path: '/reset-password-validate',
        name: 'ResetPasswordValidate',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "auth-code" */ '../views/authentication/reset-password/ResetPasswordValidate.vue'
            )
    },
    // Reset password new password route
    {
        path: '/reset-password-change',
        name: 'ResetPasswordChange',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "signup-reset-password" */ '../views/authentication/reset-password/ResetPasswordChange.vue'
            )
    },
    /** Success message. */
    {
        path: '/success-message',
        name: 'Success',
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "succes-message" */ '../views/authentication/Success.vue'
            )
    },
    /** Page not found need to be last definition. */
    {
        path: '*',
        name: 'NotFound',
        component: NotFound
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    /** match all the routes that have requiresAuth meta parameter. */
    if (to.matched.some(route => route.meta.requiresAuth)) {
        /** If user is logged in, go to the requested page. */
        if (store.getters.token && store.getters.userType) {
            if (store.getters.error !== '') {
                store.state.general.error = ''
            }
            if (store.getters.errors !== '') {
                store.state.general.errors = ''
            }
            next()
        } else {
            /** Remove token and user type from localStorage. */
            localStorage.removeItem('token')
            localStorage.removeItem('type')

            /** If user is not authenticated, go to login and after that redirect to the requested page with required authentication. */
            next({ name: 'Login', query: { redirect: to.fullPath } })
        }
        /** If the 'to' page does not require authentication, check if the user is already authenticated. */
    } else if (store.getters.token && store.getters.userType) {
        next({ name: 'Dashboard' })
    } else {
        if (store.getters.error !== '') {
            store.state.general.error = ''
        }
        if (store.getters.errors !== '') {
            store.state.general.errors = ''
        }

        next()
    }
})

export default router
