import axios from 'axios'

const state = {
    members: [],
    activeMembers: [],
    addMemberStatus: false,
    editMemberStatus: false
}

const getters = {
    allMembers: (state) => state.members,
    activeMembers: (state) => state.activeMembers,
    addMemberStatus: (state) => state.addMemberStatus,
    editMemberStatus: (state) => state.editMemberStatus
}

const actions = {
    /** Action to get all the members. */
    async getMembers ({ commit }, payload) {
        await axios.get('member-list', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setMembers', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })
    },

    /** Action to get all the members. */
    async getActiveMembers ({ commit }, payload) {
        await axios.get('member-active-list', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setActiveMembers', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })
    },

    async addMember ({ commit }, payload) {
        await axios.post('member-create', payload.member, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('addMemberStatus', true)
            })
            .catch(error => {
                commit('addMemberStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async editMember ({ commit }, payload) {
        await axios.post('member-edit', payload.member, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                commit('changeMember', payload.member)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('editMemberStatus', true)
            })
            .catch(error => {
                commit('editMemberStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Action for deleting a member. */
    async deleteMember ({ commit }, payload) {
        await axios.post('member-delete', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                /** Clean state of error. */
                commit('error', '')

                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    }
}

const mutations = {
    /** Set all members mutation. */
    setMembers: (state, members) => {
        state.members = members
    },

    /** Set all members mutation. */
    setActiveMembers: (state, members) => {
        state.activeMembers = members
    },
    /** Edit a member mutation. */
    changeMember: (state, memberEdit) => {
        for (const member in state.members) {
            if (state.members[member].id === memberEdit.id) {
                state.members[member] = memberEdit
            }
        }

        state.members = [...state.members]
    },
    /** Mutation for the status of the add member. */
    addMemberStatus: (state, status) => {
        state.addMemberStatus = status
    },
    /** Mutation for the status of the edit member. */
    editMemberStatus: (state, status) => {
        state.editMemberStatus = status
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
