import axios from 'axios'

const state = {
    counties: [],
    cities: [],
    services: [],
    citizenshipsAndNationalities: [],
    income_ranges: [],
    marital_statuses: [],
    occupations: [],
    psychiatric_history: [],
    relationships: [],
    religions: [],
    school_levels: [],
    substances: [],
    criminal_records: [],
    domestic_violences: [],
    genders: []
}

const getters = {
    counties: (state) => state.counties,
    cities: (state) => state.cities,
    services: (state) => state.services,
    citizenshipsAndNationalities: state => state.citizenshipsAndNationalities,
    income_ranges: state => state.income_ranges,
    marital_statuses: state => state.marital_statuses,
    occupations: state => state.occupations,
    psychiatric_history: state => state.psychiatric_history,
    relationships: state => state.relationships,
    religions: state => state.religions,
    school_levels: state => state.school_levels,
    substances: state => state.substances,
    criminal_records: state => state.criminal_records,
    domestic_violences: state => state.domestic_violences,
    genders: state => state.genders
}

const actions = {
    // Fetch static data for counties from api
    async fetchCounties ({ commit }) {
        /** Check if no counties are saved in the state. */
        if (!state.counties.length) {
            /** Make the API call. */
            const getCities = axios.get('counties')

            getCities.then(response => {
                /** Save the response data in the state */
                commit('setCounties', response.data)
            })

            return getCities
        }
    },
    // Fetch static data for cities from api
    async fetchCities ({ commit }, id) {
        /** Make the API call. */
        const response = await axios.get(`cities?county=${id}`)
        /** Save the response data in the state */
        commit('setCities', response.data)
    },
    async fetchCitiesReturn ({ commit }, id) {
        const response = await axios.get(`cities?county=${id}`)
        return response.data
    },
    // Fetch static data for services from api
    async fetchServices ({ commit }) {
        /** Check if no services are saved in the state. */
        if (!state.services.length) {
            /** Make the API call. */
            const response = await axios.get('services')
            /** Save the response data in the state */
            commit('setServices', response.data)
        }
    },

    async fetchCitizenshipsAndNationalities ({ commit }, payload) {
        if (!state.citizenshipsAndNationalities.length) {
            /** Make the API call. */
            const response = await axios.get('citizenships', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setCitizenshipsAndNationalities', response.data)
        }
    },

    async fetchIncomeRanges ({ commit }, payload) {
        if (!state.income_ranges.length) {
            /** Make the API call. */
            const response = await axios.get('income-ranges', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setIncomeRanges', response.data)
        }
    },

    async fetchMaritalStatuses ({ commit }, payload) {
        if (!state.marital_statuses.length) {
            /** Make the API call. */
            const response = await axios.get('marital-statuses', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setMaritalStatuses', response.data)
        }
    },

    async fetchOccupations ({ commit }, payload) {
        if (!state.occupations.length) {
            /** Make the API call. */
            const response = await axios.get('occupations', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setOccupations', response.data)
        }
    },

    async fetchPsychiatricHistory ({ commit }, payload) {
        if (!state.psychiatric_history.length) {
            /** Make the API call. */
            const response = await axios.get('psychiatric-history', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setPsychiatricHistory', response.data)
        }
    },

    async fetchRelationships ({ commit }, payload) {
        if (!state.relationships.length) {
            /** Make the API call. */
            const response = await axios.get('relationships', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setRelationships', response.data)
        }
    },

    async fetchReligions ({ commit }, payload) {
        if (!state.religions.length) {
            /** Make the API call. */
            const response = await axios.get('religions', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setReligions', response.data)
        }
    },

    async fetchSchoolLevels ({ commit }, payload) {
        if (!state.school_levels.length) {
            /** Make the API call. */
            const response = await axios.get('school-levels', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setSchoolLevels', response.data)
        }
    },

    async fetchSubstances ({ commit }, payload) {
        if (!state.substances.length) {
            /** Make the API call. */
            const response = await axios.get('substances', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setSubstances', response.data)
        }
    },

    async fetchCriminalRecords ({ commit }, payload) {
        if (!state.criminal_records.length) {
            /** Make the API call. */
            const response = await axios.get('criminal-records', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setCriminalRecords', response.data)
        }
    },

    async fetchDomesticViolences ({ commit }, payload) {
        if (!state.domestic_violences.length) {
            /** Make the API call. */
            const response = await axios.get('domestic-violences', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setDomesticViolences', response.data)
        }
    },

    async fetchGenders ({ commit }, payload) {
        if (!state.genders.length) {
            /** Make the API call. */
            const response = await axios.get('genders', {
                headers: {
                    Authorization: `Bearer ${payload.token}`
                }
            })
            /** Save the response data in the state */
            commit('setGenders', response.data)
        }
    }
}

const mutations = {
    setCounties: (state, counties) => (state.counties = counties),
    setCities: (state, cities) => (state.cities = cities),
    setServices: (state, services) => (state.services = services),
    setCitizenshipsAndNationalities: (state, citizenshipsAndNationalities) => (state.citizenshipsAndNationalities = citizenshipsAndNationalities),
    setIncomeRanges: (state, incomeRanges) => (state.income_ranges = incomeRanges),
    setMaritalStatuses: (state, maritalStatuses) => (state.marital_statuses = maritalStatuses),
    setOccupations: (state, occupations) => (state.occupations = occupations),
    setPsychiatricHistory: (state, psychiatricHistory) => (state.psychiatric_history = psychiatricHistory),
    setRelationships: (state, relationships) => (state.relationships = relationships),
    setReligions: (state, religions) => (state.religions = religions),
    setSchoolLevels: (state, schoolLevels) => (state.school_levels = schoolLevels),
    setSubstances: (state, substances) => (state.substances = substances),
    setCriminalRecords: (state, criminalRecords) => (state.criminal_records = criminalRecords),
    setDomesticViolences: (state, domesticViolences) => (state.domestic_violences = domesticViolences),
    setGenders: (state, genders) => (state.genders = genders)
}

export default {
    state,
    getters,
    actions,
    mutations
}
