import axios from 'axios'

const state = {
    forms: [],
    publishedForms: [],
    deleteFormStatus: false,
    createFormStatus: false,
    updateFormStatus: false
}

const getters = {
    allForms: (state) => state.forms,
    publishedForms: (state) => state.publishedForms,
    deleteStatus: (state) => state.deleteFormStatus,
    createStatus: (state) => state.createFormStatus,
    updateStatus: (state) => state.updateFormStatus
}

const actions = {
    /** Action to get all the forms. */
    async getForms ({ commit }, payload) {
        const getData = axios.get('form-list', { headers: { Authorization: `Bearer ${payload.token}` } })

        getData
            .then(response => {
                commit('setForms', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })

        return getData
    },

    /** Action to get all the forms. */
    async getPublishedForms ({ commit }, payload) {
        const getData = axios.get(`case-available-forms?case_id=${payload.case_id}`, { headers: { Authorization: `Bearer ${payload.token}` } })

        getData
            .then(response => {
                commit('setPublishedForms', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })

        return getData
    },

    /** Action for saving the form. */
    async addForm ({ commit }, payload) {
        const addedForm = axios.post('form-create', payload, { headers: { Authorization: `Bearer ${payload.token}` } })
        addedForm
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** Status for form create. */
                commit('createFormStatus', true)
            })
            .catch(error => {
                /** Status for form create. */
                commit('createFormStatus', false)
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })

        return addedForm
    },

    /** Action for updating the form. */
    async updateForm ({ commit }, payload) {
        const updateData = axios.post('form-update', payload, { headers: { Authorization: `Bearer ${payload.token}` } })

        updateData
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** Status for form create. */
                commit('createFormStatus', true)
            })
            .catch(error => {
                /** Status for form create. */
                commit('createFormStatus', false)
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                    console.log(error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
        return updateData
    },

    /** Action for deleting the form. */
    async deleteForm ({ commit }, payload) {
        await axios.post('form-delete', payload, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                /** Clean state of errors */
                commit('error', '')

                /** Status for displaying the message box. */
                commit('deleteFormStatus', true)
            })
            .catch(error => {
                /** Status for displaying the message box. */
                commit('deleteFormStatus', true)

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    }
}

const mutations = {
    /** Set all forms mutation. */
    setForms: (state, forms) => (state.forms = forms),
    setPublishedForms: (state, published) => (state.publishedForms = published),
    deleteFormStatus: (state, status) => (state.deleteFormStatus = status),
    createFormStatus: (state, status) => (state.createFormStatus = status)
}

export default {
    state,
    getters,
    actions,
    mutations
}
