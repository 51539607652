<template>
    <div class="sidebar-container">
        <div>
            <div class="mt-30 px-30">
                <img
                    class="mx-auto lg:mx-0"
                    :src="require('@/assets/images/sunrise.svg')"
                >
            </div>
            <ul class="flex flex-col items-center mt-60 px-12">
                <Link
                    @clicked="show = false"
                    url="/dashboard"
                    :linkText="$t('sidebarLinkTextSunrise')"
                    :img="require('@/assets/images/icons/sun.svg')"
                />
                <Link
                    @clicked="show = false"
                    url="/cases"
                    :linkText="$t('sidebarLinkTextCases')"
                    :img="require('@/assets/images/icons/files.svg')"
                />
                <li
                    class="link-container"
                    :class="[show ? 'services-dropdown-link' : 'services-link']"
                    @click="show = !show"
                >
                    <router-link
                        class="link nav-link text-purple-600"
                        to="/services/all-services"
                        @click="show = true"
                    >
                        <img
                            :src="require('@/assets/images/icons/briefcase.svg')"
                            alt="alt"
                        >
                        {{$t('sidebarLinkTextServices')}}
                    </router-link>

                    <!-- Services submenu -->
                    <ul
                        v-if="show"
                        class="dropdown-link"
                        @click.stop
                    >
                        <li>
                            <router-link
                                class="small-link"
                                to="/services/all-services"
                                @click="show = true"
                            >
                                {{ $t('allServicesText') }}
                            </router-link>
                        </li>
                        <li>
                            <router-link
                                class="small-link"
                                :to="{ name: 'NetworkServices'}"
                                @click="show = true"
                            >
                                {{ $t('networkServicesText') }}
                            </router-link>
                        </li>
                        <li v-if="userTypeManager">
                            <router-link
                                class="small-link"
                                to="/services/requested-services"
                                @click="show = true"
                            >
                                {{ $t('requestedServicesText') }}
                                <span class="notification" v-if="lastRequestedServices.length > 0">{{ lastRequestedServices.length }}</span>
                            </router-link>
                        </li>
                    </ul>
                </li>
                <Link
                    v-if="userTypeManager"
                    @clicked="show = false"
                    url="/members"
                    :linkText="$t('sidebarLinkTextMembers')"
                    :img="require('@/assets/images/icons/members.svg')"
                />
                <Link
                    v-if="userTypeManager"
                    @clicked="show = false"
                    url="/forms"
                    :linkText="$t('sidebarLinkTextFormulation')"
                    :img="require('@/assets/images/icons/layout.svg')"
                />
                <Link
                    @clicked="show = false"
                    url="/profile"
                    :linkText="$t('sidebarLinkTextProfile')"
                    :img="require('@/assets/images/icons/user.svg')"
                />
            </ul>
        </div>

        <div class="flex flex-col gap-y-22 ml-24 mb-22">
            <a
                :href="linkPartner"
                target="_blank"
            >
                <!-- <p class="mr-7 text-xs text-gray-500 font-titillium-normal">{{ $t('sidebarProjectText') }}</p> -->
                <p class="mr-7 text-xs mb-12 text-gray-500 font-titillium-normal">o solutie dezvoltata de</p>
                    <img
                        class="w-full"
                        :src="require('@/assets/images/logo_eeirh.png')"
                        style="width:150px;"
                    >
            </a>
            <a
                :href="linkCode4RO"
                target="_blank"
            >
                <img
                    :src="require('@/assets/images/icons/code_4ro.svg')"
                    alt=""
                    style="height:30px;"
                >
            </a>
        </div>
    </div>
</template>

<script>
import Link from '../links/Link'
import { mapGetters } from 'vuex'
export default {
    name: 'SideBarNav',
    components: {
        Link
    },
    data () {
        return {
            show: false,
            linkCode4RO: process.env.VUE_APP_CODE4RO,
            linkPartner: process.env.VUE_APP_PARTNER
        }
    },
    computed: {
        ...mapGetters(['userTypeManager', 'token', 'lastRequestedServices'])
    }
}
</script>
