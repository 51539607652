import axios from 'axios'
import router from '@/router'
import i18n from '../../../i18n'

const state = {
    /** Data object used for signup-init fields. */
    signup_init_data: {
        ngo_name: '',
        cif: '',
        registration_number: '',
        county_id: '',
        city_id: null,
        address: '',
        website: '',
        description: '',
        observations: '',
        user_name: '',
        user_email: '',
        user_phone: '',
        consent: false,
        services: []
    },
    activation_code: '',
    sms_code: ''
}

const getters = {
    /** Getter for initial signup_init_data state. */
    signupData: (state) => state.signup_init_data,
    /** Get activation_code && sms_code. */
    activationCode: (state) => state.activation_code,
    smsCode: (state) => state.sms_code
}

const actions = {

    /** Post request action for signup-init form. */
    async signupInit ({ commit }, payload) {
        await axios.post('signup-init', payload)
            .then(response => {
                /** If success redirect to success message page. */
                router.push({ name: 'Success', params: { signupData: payload, fromRegister: 'true', successTitle: i18n.t('succesMessageTitle'), successSubtitle: i18n.t('successMessageSubtitle') } })

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')
            })
            .catch(signupInitErrors => {
                if (typeof signupInitErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', signupInitErrors.response.data.errors)
                }

                if (typeof signupInitErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', signupInitErrors.response.data.error)
                }
            })
    },

    /** Post request action for signup activate code form. */
    async signupActivateCode ({ commit }, code) {
        await axios.post('signup-activate', code)
            .then(response => {
                /** Commit mutation for signup activate code. */
                commit('signupActivateCodeCommit', code.activation_code)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** If success redirect to enter code page. */
                router.push({ name: 'SignupValidate' })
            })
            .catch(activateCodeErrors => {
                if (typeof activateCodeErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', activateCodeErrors.response.data.errors)
                }

                if (typeof activateCodeErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', activateCodeErrors.response.data.error)
                }
            })
    },

    /** Post request action for signup validate code form. */
    async signupValidateCode ({ commit }, payload) {
        await axios.post('signup-validate', payload)
            .then(response => {
                /** Commit mutation for signup validate code. */
                commit('signupValidateCodeCommit', payload.sms_code)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** If success redirect to set password page. */
                router.push({ name: 'SignupPassword' })
            })
            .catch(validateCodeErrors => {
                if (typeof validateCodeErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', validateCodeErrors.response.data.errors)
                }

                if (typeof validateCodeErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', validateCodeErrors.response.data.error)
                }
            })
    },

    /** Post request action for signup set password form. */
    async signupSetPassword ({ commit }, payload) {
        await axios.post('signup-password', payload)
            .then(response => {
                /** Commit mutation for signup auth token. */
                commit('signupSetTokenCommit', response.data.token)
                /** Commit mutation for signup user type. */
                commit('signupSetTypeCommit', response.data.type)

                /** Save token and user type in localStorage. */
                localStorage.setItem('token', response.data.token)
                localStorage.setItem('type', response.data.type)

                /** Remove "activation_code" and "sms_code" from state. */
                commit('signupActivateCodeCommit', null)
                commit('signupValidateCodeCommit', null)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                /** If success redirect to set password page. */
                router.push({ name: 'Dashboard' })
            })
            .catch(setPasswordErrors => {
                /** If errors remove "token" and "type" from localStorage. */
                localStorage.removeItem('token')
                localStorage.removeItem('type')

                if (typeof setPasswordErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', setPasswordErrors.response.data.errors)
                }

                if (typeof setPasswordErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', setPasswordErrors.response.data.error)
                }
            })
    }
}

const mutations = {
    /** Signup activate code mutation. */
    signupActivateCodeCommit: (state, code) => (state.activation_code = code),
    /** Signup validate code mutation. */
    signupValidateCodeCommit: (state, code) => (state.sms_code = code)
}

export default {
    state,
    getters,
    actions,
    mutations
}
