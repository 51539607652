import axios from 'axios'

const state = {
    networkServices: [],
    requestServiceStatus: false
}

const getters = {
    allNetworkServices: state => state.networkServices,
    requestServiceStatus: state => state.requestServiceStatus
}

const actions = {
    async getNetworkServices ({ commit }, payload) {
        await axios.get('service-shared-list', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setNetworkServices', response.data)
            })
            .catch(error => {
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async makeServiceRequest ({ commit }, payload) {
        await axios.post('request-create', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('makeServiceRequested', payload.request.service.id)

                commit('requestServiceStatus', true)
            })
            .catch(error => {
                commit('requestServiceStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    }
}

const mutations = {
    setNetworkServices: (state, services) => {
        state.networkServices = services
    },
    /** Mutation to update the requested field of a service */
    makeServiceRequested: (state, id) => {
        for (const service of state.networkServices) {
            if (service.id === id) {
                service.requested = true
            }
        }
    },
    /** Mutation for the status of the request. */
    requestServiceStatus: (state, status) => {
        state.requestServiceStatus = status
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
