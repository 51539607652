import axios from 'axios'

const state = {
    cases: [],
    availableServices: [],
    requestStatus: false,
    lastModifiedCases: [],
    inProgressCases: '',
    registeredCases: '',
    reportedCases: '',
    caseAdd: {
        aggressor: {
            adults_dependents_number: 0,
            age: 0,
            birth_date: '',
            gender: '',
            children_dependents_number: 0,
            citizenships: [],
            criminal_records: '',
            current_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            domestic_violence_cases: [],
            elderly_dependents_number: 0,
            family_doctor_name: '',
            family_doctor_phone: '',
            first_name: '',
            has_criminal_records: null,
            has_dependents: 0,
            has_domestic_violence: 0,
            has_family_doctor: 0,
            has_psychiatric_history: null,
            has_substance_use: null,
            income_range: '',
            job: '',
            last_name: '',
            legal_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            marital_status: '',
            nationality: '',
            occupations: [],
            phone: '',
            pid: '',
            psychiatric_history: '',
            religion: '',
            school_level: '',
            substance_use_cases: [],
            teenagers_dependents_number: 0
        },
        consent: 0,
        custom_relationship_type: '',
        forensic_certificate_number: '',
        has_forensic_certificate: 0,
        has_hospitalization: 0,
        has_medical_care: 0,
        has_medical_leave: 0,
        hospitalization_days_number: 0,
        medical_care_days_number: 0,
        medical_leave_days_number: 0,
        relationship_type: '',
        status: '',
        victim: {
            adults_dependents_number: 0,
            age: 0,
            birth_date: '',
            gender: '',
            children_dependents_number: 0,
            citizenships: [],
            criminal_records: '',
            current_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            domestic_violence_cases: [],
            elderly_dependents_number: 0,
            family_doctor_name: '',
            family_doctor_phone: '',
            first_name: '',
            has_criminal_records: null,
            has_dependents: 0,
            has_domestic_violence: 0,
            has_family_doctor: 0,
            has_psychiatric_history: null,
            has_substance_use: null,
            income_range: '',
            job: '',
            last_name: '',
            legal_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            marital_status: '',
            nationality: '',
            occupations: [],
            phone: '',
            pid: '',
            psychiatric_history: '',
            religion: '',
            school_level: '',
            substance_use_cases: [],
            teenagers_dependents_number: 0
        }
    },
    caseAddInitial: {
        aggressor: {
            adults_dependents_number: 0,
            age: 0,
            birth_date: '',
            gender: '',
            children_dependents_number: 0,
            citizenships: [],
            criminal_records: '',
            current_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            domestic_violence_cases: [],
            elderly_dependents_number: 0,
            family_doctor_name: '',
            family_doctor_phone: '',
            first_name: '',
            has_criminal_records: null,
            has_dependents: 0,
            has_domestic_violence: 0,
            has_family_doctor: 0,
            has_psychiatric_history: null,
            has_substance_use: null,
            income_range: '',
            job: '',
            last_name: '',
            legal_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            marital_status: '',
            nationality: '',
            occupations: [],
            phone: '',
            pid: '',
            psychiatric_history: '',
            religion: '',
            school_level: '',
            substance_use_cases: [],
            teenagers_dependents_number: 0
        },
        consent: 0,
        custom_relationship_type: '',
        forensic_certificate_number: '',
        has_forensic_certificate: 0,
        has_hospitalization: 0,
        has_medical_care: 0,
        has_medical_leave: 0,
        hospitalization_days_number: 0,
        medical_care_days_number: 0,
        medical_leave_days_number: 0,
        relationship_type: '',
        status: '',
        victim: {
            adults_dependents_number: 0,
            age: 0,
            birth_date: '',
            gender: '',
            children_dependents_number: 0,
            citizenships: [],
            criminal_records: '',
            current_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            domestic_violence_cases: [],
            elderly_dependents_number: 0,
            family_doctor_name: '',
            family_doctor_phone: '',
            first_name: '',
            has_criminal_records: null,
            has_dependents: 0,
            has_domestic_violence: 0,
            has_family_doctor: 0,
            has_psychiatric_history: null,
            has_substance_use: null,
            income_range: '',
            job: '',
            last_name: '',
            legal_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            marital_status: '',
            nationality: '',
            occupations: [],
            phone: '',
            pid: '',
            psychiatric_history: '',
            religion: '',
            school_level: '',
            substance_use_cases: [],
            teenagers_dependents_number: 0
        }
    },
    caseEditData: {
        aggressor: {
            adults_dependents_number: 0,
            age: 0,
            birth_date: '',
            gender: '',
            children_dependents_number: 0,
            citizenships: [],
            criminal_records: '',
            current_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            domestic_violence_cases: [],
            elderly_dependents_number: 0,
            family_doctor_name: '',
            family_doctor_phone: '',
            first_name: '',
            has_criminal_records: null,
            has_dependents: 0,
            has_domestic_violence: 0,
            has_family_doctor: 0,
            has_psychiatric_history: null,
            has_substance_use: null,
            income_range: '',
            job: '',
            last_name: '',
            legal_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            marital_status: '',
            nationality: '',
            occupations: [],
            phone: '',
            pid: '',
            psychiatric_history: '',
            religion: '',
            school_level: '',
            substance_use_cases: [],
            teenagers_dependents_number: 0
        },
        consent: 0,
        custom_relationship_type: '',
        forensic_certificate_number: '',
        has_forensic_certificate: 0,
        has_hospitalization: 0,
        has_medical_care: 0,
        has_medical_leave: 0,
        hospitalization_days_number: 0,
        medical_care_days_number: 0,
        medical_leave_days_number: 0,
        relationship_type: '',
        status: '',
        victim: {
            adults_dependents_number: 0,
            age: 0,
            birth_date: '',
            gender: '',
            children_dependents_number: 0,
            citizenships: [],
            criminal_records: '',
            current_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            domestic_violence_cases: [],
            elderly_dependents_number: 0,
            family_doctor_name: '',
            family_doctor_phone: '',
            first_name: '',
            has_criminal_records: null,
            has_dependents: 0,
            has_domestic_violence: 0,
            has_family_doctor: 0,
            has_psychiatric_history: null,
            has_substance_use: null,
            income_range: '',
            job: '',
            last_name: '',
            legal_residence: {
                address: '',
                city: {},
                county: {},
                type: null
            },
            marital_status: '',
            nationality: '',
            occupations: [],
            phone: '',
            pid: '',
            psychiatric_history: '',
            religion: '',
            school_level: '',
            substance_use_cases: [],
            teenagers_dependents_number: 0
        }
    },
    searchedCases: [],
    query: '',
    chartData: {}
}

const getters = {
    cases: state => state.cases,
    caseAdd: state => state.caseAdd,
    caseEditData: state => state.caseEditData,
    availableServices: state => state.availableServices,
    requestStatus: state => state.requestStatus,
    lastModifiedCases: state => state.lastModifiedCases,
    inProgressCases: state => state.inProgressCases,
    registeredCases: state => state.registeredCases,
    reportedCases: state => state.reportedCases,
    searchResults: state => state.searchedCases,
    chartData: state => state.chartData
}

const actions = {
    setCaseEditData ({ commit }, payload) {
        commit('setCaseEditData', payload)
    },

    async getCases ({ commit }, payload) {
        const caseList = axios.get('case-list', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
        caseList
            .then(response => {
                commit('setCases', response.data)
            })
            .catch(error => {
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })

        return caseList
    },

    async addCase ({ commit }, payload) {
        const forensicCertificateNumber = payload.case.case.forensic_certificate_number !== null ? payload.case.case.forensic_certificate_number.toString() : ''
        payload.case.case.forensic_certificate_number = forensicCertificateNumber

        await axios.post('case-create', payload.case, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async editCase ({ commit }, payload) {
        const forensicCertificateNumber = payload.case.case.forensic_certificate_number !== null ? payload.case.case.forensic_certificate_number.toString() : ''
        payload.case.case.forensic_certificate_number = forensicCertificateNumber
        await axios.post('case-edit', payload.case, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('updateRequestStatus', true)

                commit('errors', '')
                commit('error', '')
            })
            .catch(error => {
                commit('updateRequestStatus', false)
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async archiveCase ({ commit }, payload) {
        await axios.post('case-archive', payload.caseToArchive, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('archiveCase', payload.caseToArchive)
                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async unarchiveCase ({ commit }, payload) {
        await axios.post('case-unarchive', payload.caseToUnarchive, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('unarchiveCase', payload.caseToUnarchive)
                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async getAvailableServices ({ commit }, payload) {
        await axios.get(`case-available-services?case_id=${payload.case_id}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setAvailableServices', response.data)
            })
            .catch(error => {
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async addServiceAllocations ({ commit }, payload) {
        await axios.post('case-add-service', payload.services, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                response.data.case_id = payload.services.case_id
                commit('addAllocations', response.data)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async editServiceAllocation ({ commit }, payload) {
        await axios.post('case-edit-service', payload.allocation.allocation, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                commit('editAllocation', payload.allocation)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async updateHoursDone ({ commit }, payload) {
        await axios.post('report-create', payload.allocation.allocation, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('updateRequestStatus', true)
                commit('editAllocation', payload.allocation)

                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')
            })
            .catch(error => {
                commit('updateRequestStatus', false)
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    async deleteServiceAllocation ({ commit }, payload) {
        const allocation = {
            id: payload.allocation.id
        }
        console.log(allocation)
        await axios.post('case-delete-service', allocation, { headers: { Authorization: `Bearer ${payload.token}` } })
            .then(response => {
                commit('deleteAllocation', payload.allocation)

                commit('updateRequestStatus', true)
            })
            .catch(error => {
                commit('updateRequestStatus', false)

                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Action to get last cases. */
    async getLastModifiedCases ({ commit }, payload) {
        await axios.get('case-last-modified', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setLastModifiedCases', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })
    },

    /** Action to get in progress cases. */
    async getCaseInProgress ({ commit }, payload) {
        await axios.get('case-in-progress', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setCaseInProgress', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })
    },

    /** Action to get registered cases. */
    async getRegisteredCases ({ commit }, payload) {
        await axios.get('case-registered', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setRegisteredCases', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })
    },

    /** Action to get reported cases. */
    async getReportedCases ({ commit }, payload) {
        await axios.get('case-total-reported', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('setReportedCases', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })
    },

    /** Action to get reported cases. */
    async getSearchedCases ({ commit }, payload) {
        const search = axios.get(`case-search?search=${payload.query}`, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
        search
            .then(response => {
                commit('setSearchedCases', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })
        return search
    },

    /** Action to get the chart data for the dashboard page. */
    async getDashboardChartData ({ commit }, payload) {
        await axios.get('case-linechart', {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                commit('updateChartData', response.data)
            })
            .catch(error => {
                console.log(error.response)
            })
    },

    /** Action to clean add case state value. */
    async cleanState ({ commit }) {
        commit('cleanState')
    }
}

const mutations = {
    setCaseEditData: (state, caseEditData) => {
        state.caseEditData = caseEditData
    },
    setCases: (state, cases) => {
        state.cases = cases
    },
    archiveCase: (state, caseId) => {
        for (const caseToArchive of state.cases) {
            // eslint-disable-next-line eqeqeq
            if (caseToArchive.id == caseId.case_id) {
                caseToArchive.status = 'archived'
            }
        }
    },
    unarchiveCase: (state, caseId) => {
        for (const caseToUnarchive of state.cases) {
            // eslint-disable-next-line eqeqeq
            if (caseToUnarchive.id == caseId.case_id) {
                caseToUnarchive.status = 'in progress'
            }
        }
    },
    setAvailableServices: (state, services) => {
        state.availableServices = services
    },
    /** Add allocations mutation */
    addAllocations: (state, allocationsToAdd) => {
        for (const caseToEdit in state.cases) {
            if (state.cases[caseToEdit].id === allocationsToAdd.case_id) {
                delete allocationsToAdd.case_id
                for (const allocation of allocationsToAdd) {
                    state.cases[caseToEdit].services.push(allocation)
                }
            }
        }
    },
    /** Edit allocation mutation */
    editAllocation: (state, allocationToEdit) => {
        for (const caseToEdit in state.cases) {
            if (state.cases[caseToEdit].id === allocationToEdit.case_id) {
                for (const service in state.cases[caseToEdit].services) {
                    if (state.cases[caseToEdit].services[service].id === allocationToEdit.allocationForFrontend.id) {
                        state.cases[caseToEdit].services[service] = allocationToEdit.allocationForFrontend
                    }
                }
            }
        }
    },
    /** Delete allocation mutation */
    deleteAllocation: (state, allocationToDelete) => {
        for (const caseToEdit in state.cases) {
            if (state.cases[caseToEdit].id === allocationToDelete.case_id) {
                for (const service in state.cases[caseToEdit].services) {
                    if (state.cases[caseToEdit].services[service].id === allocationToDelete.id) {
                        state.cases[caseToEdit].services.splice(service, 1)
                    }
                }
            }
        }
    },
    /** Mutation for the status of the request. */
    updateRequestStatus: (state, status) => {
        state.requestStatus = status
    },
    /** Set all members mutation. */
    setLastModifiedCases: (state, lastModifiedCases) => (state.lastModifiedCases = lastModifiedCases),
    /** Set all members mutation. */
    setCaseInProgress: (state, inProgressCases) => (state.inProgressCases = inProgressCases),
    /** Set all members mutation. */
    setRegisteredCases: (state, registeredCases) => (state.registeredCases = registeredCases),
    /** Set all members mutation. */
    setReportedCases: (state, reportedCases) => (state.reportedCases = reportedCases),
    /** Set searched cases. */
    setSearchedCases: (state, searched) => (state.searchedCases = searched),
    /** Mutation to update the chart data in the dashboard page. */
    updateChartData: (state, chartData) => (state.chartData = chartData),
    removeCaseFile: (state, payload) => {
        for (const caseToFind of state.cases) {
            if (caseToFind.id === payload.case_id) {
                for (const file in caseToFind.files) {
                    if (caseToFind.files[file].id === payload.file_id) {
                        caseToFind.files.splice(file, 1)
                    }
                }
            }
        }
    },
    cleanState: (state) => (state.caseAdd = state.caseAddInitial)
}

export default {
    state,
    getters,
    actions,
    mutations
}
