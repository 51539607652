const state = {
    /** userTypeManager */
    userTypeNGOManager: 'ngo-manager',
    /** userTypeStaff */
    userTypeNGOStaff: 'ngo-staff',
    phonePrefix: '+40',
    /** Errors object for retriving multiple errors response. */
    errors: {},
    /** Error string for retriving single error response. */
    error: '',
    /** Authentication "token". */
    type: localStorage.getItem('type') || '',
    /** User" type". */
    token: localStorage.getItem('token') || ''
}

const getters = {
    /** Getter for initial errors object state. */
    errors: (state) => state.errors,
    /** Getter for initial error string state. */
    error: (state) => state.error,
    /** LocalStorage saved token. */
    token: (state) => state.token,
    /** User type ngo-staff/ngo-manager. */
    userType: (state) => state.type,
    /** Getter for user type ngo-manager. */
    manager: (state) => (state.userTypeNGOManager),
    /** Getter for user type ngo-staff. */
    staff: (state) => (state.userTypeNGOStaff),
    /** Getter to check if a user has a ngo-manager certain type */
    userTypeManager: (state) => state.type === state.userTypeNGOManager,
    /** Getter to check if a user has a ngo-manager certain type */
    userTypeStaff: (state) => state.type === state.userTypeNGOStaff,
    prefix: (state) => state.phonePrefix
}

const actions = {
    /** Erase errors action when user closes a modal after an unsuccessful request. */
    eraseErrors ({ commit }) {
        commit('errors', '')
        commit('error', '')
    }
}

const mutations = {
    /** Signup set password mutation. */
    signupSetTokenCommit: (state, token) => (state.token = token),
    signupSetTypeCommit: (state, type) => (state.type = type),
    /** Errors object mutation. */
    errors: (state, errors) => (state.errors = errors),
    /** Single error. */
    error: (state, error) => (state.error = error)
}

export default {
    state,
    getters,
    actions,
    mutations
}
