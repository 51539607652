import axios from 'axios'
import router from '@/router'

const state = {
    user: {
        name: '',
        email: '',
        phone: '',
        ngo: {
            description: '',
            county: {},
            city: {},
            address: '',
            website: '',
            cif: '',
            registration_number: '',
            services: [{ id: null }]
        }
    },
    editUserProfileStatus: false,
    editNgoStatus: false,
    resetPasswordProfileStatus: false
}

const getters = {
    /** Getter for user data. */
    user: state => state.user,
    editUserProfileStatus: state => state.editUserProfileStatus,
    editNgoStatus: state => state.editNgoStatus,
    resetPasswordProfileStatus: state => state.resetPasswordProfileStatus
}

const actions = {
    /** Get request for user profile data. */
    async fetchProfile ({ commit }, payload) {
        const getProfile = axios.get('profile-get', { headers: { Authorization: `Bearer ${payload.token}` } })

        getProfile
            .then(response => {
                /** If success extract data to fetchProfileCommit. */
                commit('fetchProfileCommit', response.data)
            })
            .catch(error => {
                /** If error log the error. */
                console.log(error.response)
            })

        return getProfile
    },

    /** Update user profile data. */
    async updateUserProfile ({ commit }, payload) {
        await axios.post('profile-update', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('editUserProfileStatus', true)
            })
            .catch(signupInitErrors => {
                commit('editUserProfileStatus', false)
                if (typeof signupInitErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', signupInitErrors.response.data.errors)
                }

                if (typeof signupInitErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', signupInitErrors.response.data.error)
                }
            })
    },

    /** Update user profile data. */
    async updateNgo ({ commit }, payload) {
        await axios.post('profile-ngo-update', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('editNgoStatus', true)
            })
            .catch(signupInitErrors => {
                commit('editNgoStatus', false)
                if (typeof signupInitErrors.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', signupInitErrors.response.data.errors)
                }

                if (typeof signupInitErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', signupInitErrors.response.data.error)
                }
            })
    },

    /** Reset password. */
    async resetPasswordProfile ({ commit }, payload) {
        await axios.post('profile-password-reset', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                /** Clean state of errors */
                commit('errors', '')
                commit('error', '')

                commit('editResetPasswordProfileStatus', true)
            })
            .catch(error => {
                commit('editResetPasswordProfileStatus', false)
                if (typeof error.response.data.errors !== 'undefined') {
                    /** If post request fails commit erros to mutation. */
                    commit('errors', error.response.data.errors)
                }

                if (typeof error.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', error.response.data.error)
                }
            })
    },

    /** Update user profile data. */
    async deleteUserProfile ({ commit }, payload) {
        await axios.post('profile-delete', payload, {
            headers: {
                Authorization: `Bearer ${payload.token}`
            }
        })
            .then(response => {
                /** Clean state of errors */
                commit('error', '')
            }).then(response => {
                localStorage.removeItem('token')
                localStorage.removeItem('type')
                router.go(0)
            })
            .catch(signupInitErrors => {
                if (typeof signupInitErrors.response.data.error !== 'undefined') {
                    /** If post request fails commit erro to mutation. */
                    commit('error', signupInitErrors.response.data.error)
                }
            })
    }
}

const mutations = {
    /** Get user profile data mutation. */
    fetchProfileCommit: (state, user) => (state.user = user),
    editUserProfileStatus: (state, status) => (state.editUserProfileStatus = status),
    editNgoStatus: (state, status) => (state.editNgoStatus = status),
    editResetPasswordProfileStatus: (state, status) => (state.resetPasswordProfileStatus = status)
}

export default {
    state,
    getters,
    actions,
    mutations
}
